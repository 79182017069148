/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.link-list {
  background: transparent;
}

.link-list > .component-content ul {
  background: transparent;
}

.link-list > .component-content li {
  background: transparent;
  display: block;
}

.link-list > .component-content li a {
  display: inline;
  color: #707070;
  position: relative;
  padding-left: 10px;
  font-size: 13px;
  text-decoration: none;
}

.link-list .button-default, .link-list .default {
  border: 1px solid #d2d2d2;
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 5px 0;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  background: #f6f6f6;
  color: #707070;
  font-weight: bold;
}

.link-list .button-default:hover, .link-list .default:hover {
  color: #707070;
  background: #f7f7f7;
}

.link-list .button-success, .link-list .success {
  font-size: 12px;
  font-size: 1.2rem;
  border: 1px solid #d2d2d2;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 5px 0;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  background: #a0ce4e;
  color: #fff;
  font-weight: bold;
}

.link-list .button-success:hover, .link-list .success:hover {
  color: #fff;
  background: #92be43;
}

.link-list .button-warning, .link-list .warning {
  font-size: 12px;
  font-size: 1.2rem;
  border: 1px solid #d2d2d2;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 5px 0;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  background: #bc4526;
  color: #fff;
  font-weight: bold;
}

.link-list .button-warning:hover, .link-list .warning:hover {
  color: #fff;
  background: #c34e30;
}

.link-list.anchor-nav {
  position: sticky;
  top: 0;
  background: #1C1C1C;
  z-index: 1030;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 1.4em;
}

.link-list.anchor-nav > .component-content {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 2.5rem;
}

.link-list.anchor-nav > .component-content h3 {
  display: none;
}

.link-list.anchor-nav > .component-content > ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.link-list.anchor-nav > .component-content > ul li {
  flex: 0 0 auto;
}

.link-list.anchor-nav > .component-content > ul .field-link a {
  display: block;
  padding: 0 1.52778em;
  color: #fff;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1em;
}

.link-list.anchor-nav > .component-content > ul .field-link a:before {
  content: none;
}

.link-list.anchor-nav > .component-content > ul .field-link a:hover {
  border-bottom: 0;
  text-decoration: underline;
}

.link-list.anchor-nav > .component-content > ul .field-link a.brochure, .link-list.anchor-nav > .component-content > ul .field-link a.website {
  font-weight: 700;
}

.link-list.anchor-nav > .component-content > ul .field-link a.brochure:after, .link-list.anchor-nav > .component-content > ul .field-link a.website:after {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  content: '';
  vertical-align: bottom;
}

.link-list.anchor-nav > .component-content > ul .field-link a.brochure:after {
  background-image: url("../images/icons/icon-download.svg");
}

.link-list.anchor-nav > .component-content > ul .field-link a.website:after {
  background-image: url("../images/icons/icon-external-site.svg");
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.link-list.list-vertical h3 {
  background: #FFFFFF;
  width: 100%;
  display: inline-block;
  padding: 3px 5px;
  border: 1px solid #d2d2d2;
}

.link-list.list-vertical a {
  border: none;
}

.link-list.list-vertical li {
  display: block;
  margin-left: 20px;
}
